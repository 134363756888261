.button{
    border-radius: 30%!important;
}
.btn-outline-primary{
    border-radius: 30%!important;
}
.bg{
  
    height: 100vh;
}
.contentc{
    margin:70px;
/* margin-right:auto;
margin-bottom:0;
margin-left:auto; */
}