.bg-login{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(54,54,99,1) 0%, rgba(0,212,255,1) 100%);
}
.wrapper {
    height: 100vh;
    width: 100%;
    background: #7554a0;
    background: linear-gradient(90deg,#7554a0 7%,#7554a0 17%,#6a5fa8 29%,#6367ae 44%,#5774b8 66%,#4687c6 83%,#2ca3db 96%,#16bced 100%,#00d4ff 0);
    display: flex;
    justify-content: center;
    align-items: center;
}