.banner{
  background-color: #09015f;
}
@media only screen and (max-width: 600px) {
  .banner {height: 5vh;}
}
@media only screen and (min-width: 600px) {
  .banner {height: 5vh;}
}
@media only screen and (min-width: 768px) {
  .banner {height: 6vh;}
} 
@media only screen and (min-width: 992px) {
  .banner {height: 5vh;}
} 
@media only screen and (min-width: 1200px) {
  .banner {height: 5vh;}
}

.MuiPaper-root {
    background-color: #8fddfd;
}
